window.addEventListener('load', function() {
    $('input.typeahead').typeahead(
        {
        source: function (query, process) {
            return $.get(expressionAutoCompleteUrl, { keyword: query }, function (data) {
                return process(data);
            });
        },
        autoSelect: false,
        updater: function (item) {
            $("#expressionTypeDiv").addClass("hide");
            return item;
        }
    })

    $('#checkAllInactiveCheckbox').on('click, change', function() {
        $('div#relations input:checkbox').prop('checked', $(this).prop('checked'));
    });

    $("#addRelationSubmit").click(function(event) {
        event.preventDefault();
        $.getJSON(expressionFindByNameUrl, { keyword : $('#related_expression').val()}, function(response) {
            var expressionId = $.parseJSON(response);
            if (Number.isInteger(expressionId) && expressionId > 0) {
                $("#addRelation").submit();
            }
            else if ($("#expressionTypeDiv").hasClass("hide")) {
                $("#expressionTypeDiv").removeClass("hide");
                $("#expression_type option:selected").prop("selected", false);
                return false;
            }
            else if (
                ['position','skill','company'].includes($('#expression_type option:selected').val())
                && $('#related_expression').val().replace(/\s/g, "").length > 0
            ) {
                $("#addRelation").submit();
            }
            else {
                $("#expressionTypeDiv").removeClass("hide");
                $("#expression_type option:selected").prop("selected", false);
                return false;
            }
        });
    })

    // Show/hide category field in main form
    $('#expressionForm').on('change', '#mainExpressionType', function(){
        let categoryDiv = $('#mainExpressionCategoryDiv');

        if ( $(this).val() == 'skill' ) {
            if ( categoryDiv.hasClass('hide') ) {
                categoryDiv.removeClass('hide');
                categoryDiv.find('select[name="category"] option:selected').prop("selected", false);
            }
        } else {
            if ( !categoryDiv.hasClass('hide') ) {
                categoryDiv.addClass('hide');
            }
        }
    });

    // Show/hide category field in modal form
    $('#addRelation').on('change', '#expression_type', function(){
        let categoryDiv = $('#expressionCategoryDiv');

        if ( $(this).val() == 'skill' ) {
            if ( categoryDiv.hasClass('hide') ) {
                categoryDiv.removeClass('hide');
                categoryDiv.find('select[name="expression_category"] option:selected').prop("selected", false);
            }
        } else {
            if ( !categoryDiv.hasClass('hide') ) {
                categoryDiv.addClass('hide');
            }
        }
    });

    $('#modalAddRelation').on('show.bs.modal', function (event) {
        var modal = $(this);

        //reset form
        modal.find('.modal-body #deletable_expression').val("").change();
        modal.find('.modal-body #deletable_expression_type').val("").change();
        modal.find('.modal-body #related_expression').val("").change();
        modal.find('.modal-body select[name=relation_type]').val($('.modal-body select[name=relation_type] option:first').val()).change();
        modal.find('.modal-body select[name=expression_type]').val("").change();
        modal.find('.modal-body select[name=category]').val("none").change();

        var button = $(event.relatedTarget); // Button that triggered the modal
        var name = button.data('name'); // Extract info from data-* attributes
        var type = button.data('type'); // Extract info from data-* attributes
        var deleteable = button.data('deleteable'); // Extract info from data-* attributes

        //var category = button.data('category'); // Extract info from data-* attributes
        if (name) {
            if (deleteable != "no") {
                modal.find('.modal-body #deletable_expression').val(name).change();
                modal.find('.modal-body #deletable_expression_type').val(type).change();
            }
            modal.find('.modal-body #related_expression').val(name).change();
            modal.find('.modal-body select[name=relation_type]').val(type).change();
            //modal.find('.modal-body select[name=expression_type]').val('skill').change();
            //modal.find('.modal-body select[name=category]').val(category).change();
        }
    })


    $('#related_expression').on('change keyup paste', function() {
        var newValue = $(this).val();
        if (!newValue.trim()) {
            $('#infoRelated').addClass('font-italic');
            $('#infoRelated').text($('#infoRelated').data('default'));
        }
        else {
            $('#infoRelated').text(newValue);
            $('#infoRelated').removeClass('font-italic');
        }
    });
    $('#relation_type').on('select click change', function() {
        $('#infoRelation').text($('#relation_type option:selected').text());
    });

});
