window.addEventListener('load', function() {
    $('input.career-segment').typeahead({
        source: function (query, process) {
            return $.get(careerAutoCompleteUrl, { keyword: query }, function (data) {
                return process(data);
            });
        }
    });

    $('input.career-position').typeahead({
        source: function (query, process) {
            return $.get(careerPositionAutoCompleteUrl, { keyword: query }, function (data) {
                return process(data);
            });
        }
    });
});

const d3 = require("d3");
const {defaultsDeep} = require("lodash/object");

KnowledgeGraphNext = {
    graphData: null,
    container: null,

    init: function(data, containerSelector) {
        this.graphData = data;
        this.container = $(containerSelector);
        this.draw();
        this.arrangeLegend();
    },

    draw: function() {

        const nodeId = $('#next-graph').data('id');
        const nodeFontSize = 16;
        const nodeRadius = 10;
        const linkThickness = 2;

        const width = 1078;
        const height = 898;

        //console.log( " data:", this.graphData );
        let graph=this.graphData;

        const svg = d3.create("svg")
            .attr("viewBox", [-width / 2, -height / 2, width, height])
            .call(d3.zoom()
                .scaleExtent([0.5, 3])
                .on("zoom", redraw))
        ;

        const group = svg.append('svg:g').attr('class', 'graph-tr');

        svg.append("svg:defs").selectAll("marker")
            .data(["end"])
            .enter().append("svg:marker")
            .attr("id", String)
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 17)
            .attr("refY", 0)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto-start-reverse")
            .append("svg:path")
            .attr("d", "M0,-5L10,0L0,5");

        const simulation = d3
            .forceSimulation()
            .nodes(graph.nodes)
            .force("charge", d3.forceManyBody().strength(-2000))
            .force("link", d3.forceLink(graph.links).id(d => d.id).strength(1))
            .force("collision", d3.forceCollide().radius(70))
            .force("x", d3.forceX())
            .force("y", d3.forceY(d => calcY(d)))
            //.force("y", d3.forceY())
            //.force("y", d3.forceY(d => d.type === "career_segment" ? -300 : d.level === "3" ? -200 : d.level === '2' ? -100 : 0).strength(1))
            .on("tick", tick);

        const drag = d3
            .drag()
            .on("start", dragstart)
            .on("drag", dragged);

        const link = group.selectAll(".link")
            .data(graph.links)
            .enter().append("line")
            .attr("class", "link")
            .attr("data-type", function(r) { return r.type })
            .attr("stroke-width", linkThickness + "px")
            .attr("stroke", function(relation){
                return getLinkColor(relation.type);
            });
        link.append("title").text(function(r) { return r.type });

        group.selectAll(".link[data-type=\"POSITION_OF\"]")
            .attr("marker-end", "url(#end)");

        const node = group.selectAll(".node")
            .data(graph.nodes)
            .enter().append("g")
            .attr("class", "node")
            .call(d3.drag()
                .on("start", dragstart)
                .on("drag", dragged));

        node.call(drag).on("click", click);

        const circle = node.append("circle")
            .attr("r", nodeRadius + "px")
            .attr("fill", function(d) {
                return ((d.id == nodeId )
                        ? '#ff0000'
                        : ((d.type == 'position')
                                ? '#2ca02c'
                                : '#1f77b4'
                        )
                );
            })
            .attr("x", -8)
            .attr("y", -2)
            .append("title").text(function(d) { return d.name + "\n" + d.type + "\n" + d.status });

        const text = node.append("text")
            .attr("font-size", nodeFontSize + "px")
            .attr("pointer-events", "none")
            .attr("dx", 22)
            .attr("dy", ".35em")
            .attr("style", "max-width: 80px; overflow: hidden; text-overflow: ellipsis;")
            .text(function(d) { return d.name });

        $(window).resize(KnowledgeGraph.arrangeLegend);

        // Legend
        const legend = svg.append('svg:g').attr('class', 'legend');
        legend.append("circle").attr("r", 6).style("fill", "#ff0000");
        legend.append("circle").attr("r", 6).style("fill", "#2ca02c");
        legend.append("line").style("stroke", getLinkColor('POSITION_OF')).style("stroke-width", "2");
        legend.append("line").style("stroke", getLinkColor('SIBLING')).style("stroke-width", "2");
        legend.append("line").style("stroke", getLinkColor('SPECIFIER')).style("stroke-width", "2");
        legend.append("line").style("stroke", getLinkColor('CAREER_PATH')).style("stroke-width", "2");
        legend.append("text").text("Actual position / Career segment").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("Position").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("POSITION OF").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("SIBLING").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("SPECIFIER").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("CAREER PATH").style("font-size", "16px").attr("alignment-baseline","middle");

        this.container.append(svg.node());

        function tick() {
            link.attr("x1", d => calcX(d.source))
                .attr("y1", d => calcY(d.source))
                .attr("x2", d => calcX(d.target))
                .attr("y2", d => calcY(d.target));

            node.attr("transform", d => "translate(" + calcX(d) + "," + calcY(d) + ")");
        }

        let specifiers = null;
        let siblings = null;

        let level1Step = 0;

        function calcY(d) {
            if (Array.isArray(specifiers) && specifiers.includes(d.id) || Array.isArray(siblings) && siblings.includes(d.id)) {
                return d.y
            }
            let y = 0;
            if (d.type === 'career_segment') {
                y -= 300;
            } else if (d.level === '2') {
                y -= 100;
            } else if (d.level === '3') {
                y -= 200;
            }
            return y;
        }

        function calcX(d) {
            collectSiblings(d);
            collectSpecifiers(d);
            return d.x;
        }

        function collectSiblings(d) {
            if (typeof d.sibling_connections != 'undefined' && siblings === null) {
                siblings = [];
                $.each(d.sibling_connections, function(key, item) {
                    siblings.push(item.id);
                })
            }
        }

        function collectSpecifiers(d) {
            if (typeof d.specifier_connections != 'undefined' && specifiers === null) {
                specifiers = [];
                $.each(d.specifier_connections, function(key, item) {
                    specifiers.push(item.id);
                })
            }
        }

        function dragstart() {
            d3.select(this).classed("fixed", true);
        }

        function dragged(event, d) {
            d.fx = clamp(event.x, -width/2, width/2);
            d.fy = clamp(event.y, -height/2, height/2);
            simulation.alpha(0.1).restart();
        }

        function click(event, d) {
            delete d.fx;
            delete d.fy;
            d3.select(this).classed("fixed", false);
            simulation.alpha(0.1).restart();
        }

        function getLinkColor(type) {
            const relationTypes = ['POSITION_OF', 'SIBLING', 'SPECIFIER', 'CAREER_PATH'];
            const scale = d3.scaleOrdinal(d3.schemeCategory10).domain(relationTypes);
            return scale(type);
        }

        function clamp(x, lo, hi) {
            return x < lo ? lo : x > hi ? hi : x;
        }

        function redraw(event) {
            group.attr("transform", event.transform)
            text.attr("font-size", (nodeFontSize / event.transform.k) + "px");
            circle.attr("r", (nodeRadius / event.transform.k) + "px");
            link.attr("stroke-width", (linkThickness / event.transform.k) + "px");
            $('svg defs marker').attr('refX', (nodeRadius * event.transform.k) + 6);
        }

    },

    arrangeLegend: function(){
        let baseX = (- this.container.width() / 2);
        let baseY = (- this.container.height() / 2) + 30;

        this.container.find('svg g.legend circle').each(function(index, item){
            item.setAttribute("cx", baseX + 10);
            item.setAttribute("cy", baseY + (index * 30));
        });

        this.container.find('svg g.legend line').each(function(index, item){
            item.setAttribute("x1", baseX + 5);
            item.setAttribute("y1", baseY + 55 + (index * 30));
            item.setAttribute("x2", baseX + 15);
            item.setAttribute("y2", baseY + 65 + (index * 30));
        });

        this.container.find('svg g.legend text').each(function(index, item){
            item.setAttribute("x", baseX + 30);
            item.setAttribute("y", baseY + (index * 30));
        });
    }
}


let initGraph = function() {
    $('#next-graph').empty();
    let data = {};
    $.each(['siblings', 'specifiers', 'levels', 'paths'], function( index, value ) {
        if ($('#'+value+'-cb').prop('checked')) {
            data[value] = true;
        }
    });
    $.ajax({
        data: data,
        url: "/career/graph-data/"+$('#next-graph').data('id'),
    }).done(function( data ) {
        KnowledgeGraphNext.init(data, '#next-graph')
    });
}

$( document ).ready(function() {
    if ( $('#next-graph').length > 0 ) {
        initGraph()
    }
})

$('.graph-option').on('change', function(){
    initGraph()
})

$('#new-career-segment').click(function () {
    $('#newCareerSegmentModal').modal('show');
});

$('#new-position').click(function () {
    $('#newPositionModal').modal('show');
});
