window.addEventListener('load', function() {
    // Typeahead init
    $('input.rel-create-typeahead').typeahead(
        {
            source: function (query, process) {
                return $.get(expressionAutoCompleteUrl, { keyword: query }, function (data) {
                    return process(data);
                });
            },
            autoSelect: false
        }
    );

    // Show/hide attribute fields for new expressions
    $('input.rel-create-typeahead').change(function() {
        let value = $(this).val();
        let optionalFields = $(this).parents("div.exp-attributes").children('.exp-type, .exp-active');

        if ( value.trim() == '' ) {
            optionalFields.addClass('hide');
            return;
        }

        $.getJSON(expressionFindByNameUrl, { keyword : value}, function(response) {
            let expressionId = $.parseJSON(response);
            if (Number.isInteger(expressionId) && expressionId > 0) {
                optionalFields.addClass('hide');
            } else {
                optionalFields.removeClass('hide');
            }
        });
    });

    // Show/hide category fields
    $('#createRelation').on('change', 'select.expression-type', function() {
        let categoryDiv = $(this).parents("div.exp-attributes").find('div.exp-category');

        if ( $(this).val() == 'skill' ) {
            if ( categoryDiv.hasClass('hide') ) {
                categoryDiv.removeClass('hide');
                categoryDiv.find('select[name$="_category"] option:selected').prop("selected", false);
            }
        } else {
            if ( !categoryDiv.hasClass('hide') ) {
                categoryDiv.addClass('hide');
            }
        }
    });

    $('input.rel-create-typeahead').trigger('change');
});
