const d3 = require("d3");

KnowledgeGraph = {
    graphData: null,
    container: null,

    init: function(data, containerSelector) {
        this.graphData = data;
        this.container = $(containerSelector);
        this.draw();
        this.arrangeLegend();
    },

    draw: function() {

        const nodeId = $('#full-chain').data('id');
        const nodeFontSize = 16;
        const nodeRadius = 10;
        const linkThickness = 2;

        const width = 1078;
        const height = 898;

        //console.log( " data:", this.graphData );
        let graph=JSON.parse(this.graphData);

        const svg = d3.create("svg")
            .attr("viewBox", [-width / 2, -height / 2, width, height])
            .call(d3.zoom()
                .scaleExtent([0.5, 3])
                .on("zoom", redraw))
        ;

        const group = svg.append('svg:g').attr('class', 'graph-tr');

        svg.append("svg:defs").selectAll("marker")
            .data(["end"])
            .enter().append("svg:marker")
            .attr("id", String)
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 17)
            .attr("refY", 0)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto-start-reverse")
            .attr("fill", getLinkColor('BROADER_TERM'))
            .append("svg:path")
            .attr("d", "M0,-5L10,0L0,5");

        const simulation = d3
            .forceSimulation()
            .nodes(graph.nodes)
            .force("charge", d3.forceManyBody().theta(0.05).distanceMin(30).strength(-1300))
            .force("link", d3.forceLink(graph.links).id(d => d.id))
            .force("x", d3.forceX())
            .force("y", d3.forceY())
            .on("tick", tick);

        const drag = d3
            .drag()
            .on("start", dragstart)
            .on("drag", dragged);

        const link = group.selectAll(".link")
            .data(graph.links)
            .enter().append("line")
            .attr("class", "link")
            .attr("data-type", function(r) { return r.type })
            .attr("stroke-width", linkThickness + "px")
            .attr("stroke", function(relation){
                return getLinkColor(relation.type);
            });
        link.append("title").text(function(r) { return r.type });

        group.selectAll(".link[data-type=\"BROADER_TERM\"]")
            .attr("marker-start", "url(#end)");

        const node = group.selectAll(".node")
            .data(graph.nodes)
            .enter().append("g")
            .attr("class", "node")
            .call(d3.drag()
                .on("start", dragstart)
                .on("drag", dragged));

        node.call(drag).on("click", click);

        const circle = node.append("circle")
            .attr("r", nodeRadius + "px")
            .attr("fill", function(d) {
                return ((d.id == nodeId )
                        ? '#ff0000'
                        : ((d.type == 'position')
                                ? '#2ca02c'
                                : '#1f77b4'
                        )
                );
            })
            .attr("x", -8)
            .attr("y", -2)
            .append("title").text(function(d) { return d.name + "\n" + d.type + "\n" + d.status });

        const text = node.append("text")
            .attr("font-size", nodeFontSize + "px")
            .attr("pointer-events", "none")
            .attr("dx", 22)
            .attr("dy", ".35em")
            .text(function(d) { return d.name });

        $(window).resize(KnowledgeGraph.arrangeLegend);

        // Legend
        const legend = svg.append('svg:g').attr('class', 'legend');
        legend.append("circle").attr("r", 6).style("fill", "#ff0000");
        legend.append("circle").attr("r", 6).style("fill", "#2ca02c");
        legend.append("circle").attr("r", 6).style("fill", "#1f77b4");
        legend.append("line").style("stroke", getLinkColor('SYNONYM_OF')).style("stroke-width", "2");
        legend.append("line").style("stroke", getLinkColor('SKILL_OF')).style("stroke-width", "2");
        legend.append("line").style("stroke", getLinkColor('BROADER_TERM')).style("stroke-width", "2");
        legend.append("line").style("stroke", getLinkColor('TYPO')).style("stroke-width", "2");
        legend.append("line").style("stroke", getLinkColor('SIMILAR')).style("stroke-width", "2");
        legend.append("text").text("Current expression").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("Position").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("Skill").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("SYNONYM OF").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("SKILL OF").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("BROADER NARROWER").style("font-size", "16px").attr("alignment-baseline","middle").attr("word-spacing","40");
        legend.append("text").text("TYPO").style("font-size", "16px").attr("alignment-baseline","middle");
        legend.append("text").text("SIMILAR").style("font-size", "16px").attr("alignment-baseline","middle");

        legend.append("line").attr('class', 'broader-sample').style("stroke", getLinkColor('BROADER_TERM')).style("stroke-width", "2");
        legend.append("svg:path").attr('class', 'broader-sample-marker').attr("fill", getLinkColor('BROADER_TERM')).attr("d", "M0,-5L10,0L0,5");

        this.container.append(svg.node());

        function tick() {
            link.attr("x1", function(d) { return d.source.x; })
                .attr("y1", function(d) { return d.source.y; })
                .attr("x2", function(d) { return d.target.x; })
                .attr("y2", function(d) { return d.target.y; });

            node.attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; });
        };

        function dragstart() {
            d3.select(this).classed("fixed", true);
        }

        function dragged(event, d) {
            d.fx = clamp(event.x, -width/2, width/2);
            d.fy = clamp(event.y, -height/2, height/2);
            simulation.alpha(0.1).restart();
        }

        function click(event, d) {
            delete d.fx;
            delete d.fy;
            d3.select(this).classed("fixed", false);
            simulation.alpha(0.1).restart();
        }

        function getLinkColor(type) {
            const relationTypes = ['SYNONYM_OF', 'SKILL_OF', 'SIMILAR', 'TYPO', 'BROADER_TERM'];
            const scale = d3.scaleOrdinal(d3.schemeCategory10).domain(relationTypes);
            return scale(type);
        }

        function clamp(x, lo, hi) {
            return x < lo ? lo : x > hi ? hi : x;
        }

        function redraw(event) {
            group.attr("transform", event.transform)
            text.attr("font-size", (nodeFontSize / event.transform.k) + "px");
            circle.attr("r", (nodeRadius / event.transform.k) + "px");
            link.attr("stroke-width", (linkThickness / event.transform.k) + "px");
            $('svg defs marker').attr('refX', (nodeRadius * event.transform.k) + 6);
        }

    },

    arrangeLegend: function(){
        let baseX = (- this.container.width() / 2);
        let baseY = (- this.container.height() / 2) + 30;

        this.container.find('svg g.legend circle').each(function(index, item){
            item.setAttribute("cx", baseX + 10);
            item.setAttribute("cy", baseY + (index * 30));
        });

        this.container.find('svg g.legend line').not('.broader-sample').each(function(index, item){
            item.setAttribute("x1", baseX + 5);
            item.setAttribute("y1", baseY + 85 + (index * 30));
            item.setAttribute("x2", baseX + 15);
            item.setAttribute("y2", baseY + 95 + (index * 30));
        });

        this.container.find('svg g.legend text').each(function(index, item){
            item.setAttribute("x", baseX + 30);
            item.setAttribute("y", baseY + (index * 30));
        });

        this.container.find('svg g.legend line.broader-sample').each(function(index, item){
            item.setAttribute("x1", baseX + 111);
            item.setAttribute("y1", baseY + 149);
            item.setAttribute("x2", baseX + 139);
            item.setAttribute("y2", baseY + 149);
        });

        this.container.find('svg g.legend path.broader-sample-marker').each(function(index, item){
            item.setAttribute("transform", "translate(" + (baseX + 137) + "," + (baseY + 149) + ")");
        });
    }
}


$('#showChainButton').click(function () {
    if($('#showChainButton').text()==='Show indirect narrowers') {
        $.ajax({
            url: "/expressions/"+$('#narrowers-chain').data('id')+"/narrowers-chaindata",
        })
        .done(function( data ) {
            $('div.graphContainer.fullChain').addClass('hide');
            $('#full-chain').empty();

            $('div.graphContainer.narrowersChain').removeClass('hide');
            KnowledgeGraph.init(data, '#narrowers-chain');

            $('#showChainButton').text('Show default chain');
            $('#graph-title-span').text('Indirect narrowers');
        });

    } else {
        $.ajax({
            url: "/expressions/"+$('#full-chain').data('id')+"/chaindata",
        })
        .done(function( data ) {
            $('div.graphContainer.narrowersChain').addClass('hide');
            $('#narrowers-chain').empty();

            $('div.graphContainer.fullChain').removeClass('hide');
            KnowledgeGraph.init(data, '#full-chain');

            $('#showChainButton').text('Show indirect narrowers');
            $('#graph-title-span').text('Default chain');
        });
    }
})

$( document ).ready(function() {
        if ( $('#full-chain').length > 0 ) {
            $.ajax({
                url: "/expressions/"+$('#full-chain').data('id')+"/chaindata",
            })
            .done(function( data ) {
                KnowledgeGraph.init(data, '#full-chain')
            });
        }
})

